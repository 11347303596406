//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import prettyBytes from 'pretty-bytes';
import iconFiles from './assets/icon-files.svg';

export default {
  name: 'ViuUpload',
  filters: {
    formatSize(size) {
      return prettyBytes(size);
    }
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    /**
     * Define el título principal del area dónde arrastrar los ficheros.
     */
    title: {
      type: String,
      default: 'Arrastra o haz clic para añadir los archivos'
    },
    /**
     * Define el subtítulo del area dónde arrastrar los ficheros.
     */
    subTitle: {
      type: String,
      default: 'Se acepta formato por "definir"'
    },
    /**
     * Campo utilizado por teceros para comprobar si somos un componente requerido en un formulario o no.
     */
    required: {
      type: Boolean,
      default: false
    },
    /**
     * Activa el estado de disabled. En caso de ser true no se podrá interactuar con el componente.
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Otorga la funcionalidad de subida multiple de ficheros al componente.
     */
    multiple: {
      type: Boolean,
      default: false
    },
    /**
     * Número mínimo de ficheros que deberán haber en el componente cuando se invoque el metodo checkInvalid.
     */
    minFiles: {
      type: Number,
      default: 0
    },
    /**
     * Número máximo de ficheros que deberán haber en el componente cuando se invoque el metodo checkInvalid.
     */
    maxFiles: {
      type: Number,
      default: 1
    },
    /**
     * Define el estado de invalido, el componente se pondrá de color rojo.
     */
    invalid: {
      type: [Boolean, String],
      default: false
    },
    /**
     * Establecer clases en elemento donde lanza los eventos para testing QA
     */
    dusk: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    dragging: false,
    files: [],
    iconFiles,
    invalidStored: false
  }),
  watch: {
    value: {
      immediate: true,
      handler: 'syncStored'
    }
  },
  methods: {
    syncStored() {
      this.files = this.value;
    },
    onChange(event) {
      this.dragging = false;
      this.invalidStored = false;
      const uploadFiles = Array.from(event.target.files);

      /**
       * Si el componente es multiple mergeamos los ficheros existentes con los nuevos subidos.
       */
      this.files = this.multiple ? [...this.files, ...uploadFiles] : uploadFiles;

      this.resetInputFile();

      this.$emit('input', this.files);
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    hasFiles() {
      return this.files.length <= 0;
    },
    resetInputFile() {
      this.$refs.input.type = 'text';
      this.$nextTick(() => {
        this.$refs.input.type = 'file';
      });
    },
    checkInvalid() {
      if (typeof this.invalid === 'string' || this.invalid === true) {
        return {
          status: true,
          reason: typeof this.invalid === 'string' ? this.invalid : ''
        };
      }

      if (this.required && this.files.length <= 0) {
        this.invalidStored = true;
        return {
          status: true,
          reason: 'El campo es requerido'
        };
      }

      if (this.minFiles > this.files.length) {
        this.invalidStored = true;
        return {
          status: true,
          reason: `Número de ficheros mínimos: ${this.minFiles}`
        };
      }

      if (this.maxFiles < this.files.length && this.multiple) {
        this.invalidStored = true;
        return {
          status: true,
          reason: `Número de ficheros máximos: ${this.maxFiles}`
        };
      }

      this.invalidStored = false;

      return {
        status: false,
        reason: ''
      };
    }
  }
};
