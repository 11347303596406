//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SignContractNotice from './sign-contract-notice.vue';
import SignContractPendingScoring from './sign-contract-pending-scoring.vue';
import UploadContract from './upload-contract-page.vue';
import { MANUAL } from '../../../../../constants/signature-types.js';

export default {
  components: {
    SignContractNotice,
    UploadContract,
    SignContractPendingScoring
  },
  props: {
    leadId: {
      type: String,
      required: true
    },
    signatureType: {
      type: Number,
      required: true
    },
    scoring: String
  },
  created() {
    this.MANUAL = MANUAL;
  },
  methods: {
    volverAtras() {
      this.$emit('back', this.$event);
    },
    continuar(data) {
      this.$emit('continue', data);
    },
    processStepData(data) {
      this.$emit('process-step-data', data);
    }
  }
};
