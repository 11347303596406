//
//
//
//
//
//
//
//
//
//
//
//
//

import StepLayout from '../step-layout.vue';
import panaImg from '$resources/images/chica.png';

export default {
  name: 'ValidateScoring',
  components: {
    StepLayout
  },
  data: () => ({
    panaImg
  })
};
