//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StepLayout from '../step-layout.vue';
import ViuUpload from '../../../../ui/file-upload/file-upload.vue';
import Button from '../../components/button.vue';
import { downloadContractPDF, uploadFile } from '../../lib/api.js';

export default {
  name: 'UploadContract',
  components: {
    StepLayout,
    ViuUpload,
    Button
  },
  props: {
    leadId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    file: [],
    fileName: '',
    downloading: false
  }),
  watch: {
    async file() {
      const { length } = this.file;
      if (length > 0) {
        this.fileName = await this.uploadFile(this.file[0]);
      }
    }
  },
  methods: {
    volver() {
      this.$emit('volver', this.$event);
    },
    async descargarContrato() {
      try {
        if (this.downloading) return;
        this.downloading = true;
        const response = await downloadContractPDF(this.leadId);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Contrato pendiente de firma.pdf');
        document.body.appendChild(link);
        link.click();

        this.downloading = false;
      } catch (error) {
        this.$notify.error(
          'Ha ocurrido un error. Inténtalo de nuevo o contacta con el administrador.'
        );
      }
    },
    async uploadFile(file) {
      const formData = new FormData();
      formData.append('file', file);
      const response = await uploadFile(this.leadId, formData);
      return response.data.fileName;
    },
    continuar() {
      const data = { contractFile: this.fileName };
      this.$emit('continue', data);
    }
  }
};
