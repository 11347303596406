//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StepLayout from '../step-layout.vue';
import firmaImg from '$resources/images/firma.png';
import Button from '../../components/button.vue';
import { fetchStepsData } from '../../lib/api.js';
import repeatIcon from '$resources/svg/repetir.svg';
import { resendDigitalSign } from '$network/api.js';
import { STARTING_COUNTDOWN_SECONDS, RESEND_SMS_LIMIT } from './waiting-signature-constants.js';

export default {
  name: 'SignContractNotice',
  components: {
    StepLayout,
    Button
  },
  props: {
    leadId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    firmaImg,
    interval: null,
    repeatIcon,
    seconds: STARTING_COUNTDOWN_SECONDS,
    timesResent: 0,
    countdownInterval: null,
    requestSent: false
  }),
  computed: {
    canChangeSignatureMethod() {
      return this.seconds === 0 && !this.requestSent;
    },
    canResendSMS() {
      return (
        this.seconds === 0 && this.requestSent === false && this.timesResent < RESEND_SMS_LIMIT
      );
    },
    maxTimesResentReached() {
      return this.timesResent === RESEND_SMS_LIMIT;
    }
  },
  async created() {
    this.interval = setInterval(this.getSteps, 10000);
    this.createCountDownInterval();
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.countdownInterval);
  },
  methods: {
    async getSteps() {
      try {
        const response = await fetchStepsData(this.leadId);
        this.$emit('process-step-data', response.data);
      } catch (error) {
        this.$notify.error('Ha ocurrido un error, contacta con el administrador.');
      }
    },
    createCountDownInterval() {
      this.countdownInterval = setInterval(this.countDown, 1000);
    },
    countDown() {
      this.seconds -= 1;
      if (this.seconds === 0) {
        clearInterval(this.countdownInterval);
      }
    },
    async reenviarFirma() {
      this.requestSent = true;
      await resendDigitalSign(this.leadId)
        .then(() => {
          this.$notify.success('Reenviado código por SMS');
        })
        .catch(() => {
          this.$notify.error(
            'No se pudo reenviar código por SMS. Intente cambiar el método de firma.'
          );
        })
        .finally(() => {
          this.timesResent += 1;
          this.seconds = STARTING_COUNTDOWN_SECONDS;
          this.createCountDownInterval();
          this.requestSent = false;
        });
    }
  }
};
